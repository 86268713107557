import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass/styled-components';

import SEO from '../components/SEO';
import { Container, Header } from '../styles/PageLayout';
import { Headline, Subtitle } from '../styles/Typography';
import Logo from '../components/Logo';

const Link = styled.a`
  font-weight: bold;
  text-decoration: underline;
`;

const SuccessPage = () => (
  <>
    <SEO title="Ironhack Alumni Platform Migration" />
    <Header>
      <Logo />
    </Header>
    <Container>
      <Headline>Thank you! You’re all set</Headline>
      <Box maxWidth="31em" textAlign="center" mt="1em" color="#717291">
        <Subtitle>
          We have sent you an email to access our new platform.
          <br />
          <br />
          <strong>
            Remember to bookmark the url of the platorm:{' '}
            <Link
              href="https://my.ironhack.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://my.ironhack.com
            </Link>
          </strong>
          <br />
          <br />
        </Subtitle>
        <Box fontSize="0.875em">
          You didn’t receive the email? <br />
          Please check your SPAM folder or{' '}
          <Link
            href="https://forms.clickup.com/f/2b3h2-3732/NFKX675JKWVWGA1FB3"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact us here
          </Link>{' '}
          so we can help you.
        </Box>
      </Box>
    </Container>
  </>
);

export default SuccessPage;
